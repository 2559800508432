<template lang="pug">
	div
		advertiser-statistics(v-if="isAdvertiser")
		publisher-statistics(v-if="isPublisher")
</template>

<script>
import AdvertiserStatistics from './AdvertiserStatistics.vue'
import PublisherStatistics from './PublisherStatistics.vue'

export default {
	name: 'Statistics',
	components: {
		AdvertiserStatistics,
		PublisherStatistics
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		isPublisher() {
			return this.USER.type === 'publisher' || (this.USER.type === 'admin' && this.USER.publisher_id);
		},
		isAdvertiser() {
			return this.USER.type === 'advertiser' || (this.USER.type === 'admin' && this.USER.advertiser_id);
		}
	},
}
</script>
