<template lang="pug">
	div.page-statistics
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="filters")
			.widget-header
				h1.title Statistics
			.widget-body
				form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
						span Filters
						i.la.la-chevron-up
					.form-row-main
						//- .form-group
						//- 	label Group By
						//- 	select.form-control(v-model="filters.group_by")
						//- 		option(v-for="t in groupByOptions", :value="t.v") {{ t.t }}
						ov-date-time-picker(v-model="filters.datePicker")
						//- .form-group
						//- 	label OfferID
						//- 	input.form-control(type="text", v-model="filters.offer_id")
						//- .form-group
						//- 	label Countries
						//- 	input.form-control(type="text", v-model="filters.countries", placeholder="US, GB...")
						//- .form-group
						//- 	label OS
						//- 	select.form-control(v-model="filters.os")
						//- 		option(v-for="o in osOptions", :value="o.v") {{ o.t }}
						//.form-group.fg-offer
						//	label Offer(s)
						//	multiselect(:multiple="true", :options="offerOptions", v-model="filters.offer_id",
						//		:limit="1", group-label="name", group-values="items", :group-select="false",
						//		track-by="id", label="name", deselect-label="", select-label="", @search-change="searchOffer",
						//		:loading="offerOptionsLoading", :internal-search="false", @open="selectOffersFilter")
						//.form-group
							label Status {{ filters.status }}
							select.form-control(v-model="filters.status")
								option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
						//.form-group
							label Group Timeframe
							select.form-control(v-model="filters.group_timeframe", style="width: 120px;")
								option(value="none") None
								option(value="hour") Hour
								option(value="day") Day
						//.form-group.no-label(v-if="USER.special_account_features")
							label.form-check-label
								input.form-check-input(type="checkbox", v-model="filters.show_groups")
								| Groups
						//.form-group.no-label
							a(href="javascript:void(0);", @click="clearFilters()") Clear filters

					.form-row-end
						.form-group
							label &nbsp;
							div
								b-button-group
									b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
										b-dropdown-item(@click="loadData(true)")
											i.la.la-download
											| Export

				p.clearfix &nbsp;
				paginate(:paginator="paginate", @update-page-size="loadData")

				.stats-table-wrapper
					table.table.table-bordered.tbl-statistics
						thead
							tr
								th.c(style="width: 80px;") {{ resultEntity }}Id
								th {{ resultEntity }}
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="impressions", style="width: 80px;") Impr.
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="clicks", style="width: 80px;") Clicks
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="installs", style="width: 80px;") Installs
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="cr", style="width: 80px;") CR(%)
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="cv", style="width: 80px;") CV
								ov-th.c(@sort="doSort()", :sorter="sortBy", field="revenue", style="width: 80px;") Spend
						tbody.summary
							tr(v-if="summary")
								td.c &nbsp;
								td.c &nbsp;
								td.c {{ summary.passed_impressions }}
								td.c {{ summary.passed_clicks }}
								td.c {{ summary.installs }}
								td.c {{ summary.cr.toLocaleString() }}
								td.c {{ summary.cv.toLocaleString() }}
								td.c.text-revenue {{ summary.revenue }}
						tbody
							tr(v-if="records.length===0")
								td(:colspan="10") No matching records were found
							tr(v-for="r in records", v-bind:key="r.key")
								td.text-left {{ r.offer.id }}
								td.text-left {{ r.offer.name }}
								td.c {{ r.passed_impressions.v }}
								td.c {{ r.passed_clicks.v }}
								td.c {{ r.installs.v }}
								td.c {{ r.cr.v.toLocaleString() }}
								td.c {{ r.cv.v.toLocaleString() }}
								td.c {{ r.revenue.v }}
				paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
// import Vue from 'vue';
import moment from 'moment';

export default {
	name: 'Statistics',
	components: {},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		groupByOptions() {
			let options = [
				{ v: 'all', t: 'All' },
				{ v: 'offer', t: 'Offer' },

			];
			console.log(this.USER);
			if (this.USER.permissions['statistics::ADVERTISERS']) {
				options.push({ v: 'advertiser', t: 'Advertiser' });
			}
			if (this.USER.permissions['statistics::SUBSOURCES']) {
				options.push({ v: 'subsource', t: 'Subsource' });
			}
			if (this.USER.permissions['statistics::BUNDLE']) {
				options.push({ v: 'bundle', t: 'Bundle' });
				options.push({ v: 'appname', t: 'Appname' });
				options.push({ v: 'bundle_offer', t: 'Bundle + Offer' });
				options.push({ v: 'appname_offer', t: 'Appname + Offer' });
			}
			// if (
			// 	{v: 'subsource', t: 'Subsource'},
			// 		{v: 'subsource2', t: 'Subsource2'},)
			return options;
		}
	},
	data() {
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			summary: null,
			records: [],
			filtersExpanded: true,
			resultEntity: '-',
			resultSubEntity: '',
			osOptions: [
				{ v: '', t: 'All' },
				{ v: 'android', t: 'Android' },
				{ v: 'ios', t: 'iOS' },
				{ v: 'other', t: 'Other' },
			],
			filters: {
				keyword: '',
				country: '',
				os: '',
				group_by: 'offer',
				offer_id: '',
				status: 'all',
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				},
			},
			sortBy: {
				field: 'revenue',
				direction: 'desc',
				sort: null,
			},
		};
	},
	methods: {
		toggleFilters: function () {
			this.filtersExpanded = !this.filtersExpanded
		},
		doSort() {
			console.log('sort');
			this.loadData();
		},
		clearFilters() {
			this.filters = {
				group_timeframe: 'none',
				offer_id: '',
				group_by: 'default',
				status: 'all',
				os: '',
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						endDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
					}
				},
			};
		},
		async loadData(doExport) {
			let params = {
				export: doExport ? 1 : 0,
				page: this.paginate.page,
				page_size: this.paginate.limit,
				// keyword: this.filters.keyword,
				os: this.filters.os,
				countries: this.filters.countries,
				group_by: this.filters.group_by,
				group_timeframe: this.filters.group_timeframe,
				offer_id: this.filters.offer_id,//.map(r => r.id).join(','),
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),//.format('YYYY-MM-DD'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),//.format('YYYY-MM-DD'),
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
			};
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				console.log(params);
				let path = 'advertisers/statistics/getList';
				console.log(path)
				let resp = await this.$ovReq.get(path, { params });
				if (doExport) {
					window.open(resp.path);
				} else {
					this.records = resp.records.slice(0, this.paginate.limit);
					this.summary = resp.summary;
					this.resultEntity = resp.entity;
					this.resultSubEntity = resp.subentity;
					this.paginate.total = resp.total;
					this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
				}
			} catch (e) {
				console.error(e);
				this.$ovNotify.error(e);
			}
			this.busy = false;
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>
